import React from "react";

function App() {
  return (
    <h1>
      <i class="fas fa-biohazard"></i> Hello
    </h1>
  );
}

export default App;
